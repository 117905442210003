<template>
    <div class="card card-body">
        <div class="alert alert-info g" v-if="type == '1'">
            <span class="text-dark">
                الإستبانة:
                <br>
                يصل لكل طالب او ولي أمر رابط خاص به ولا يمكن الاستبيان اكثر من مرة لكل طالب او ولي امر.
            </span>
        </div>
        <div class="alert alert-info g" v-if="type == '2'">
            <span class="text-dark">
                النموذج:
                <br>
                يصل للكل رابط موحد ويمكن لأي شخص القيام بملئ النموذج.
            </span>
        </div>
        <div>
            <button class="btn btn-primary g" v-b-modal.create>
                <i class="fa fa-plus"></i>
                انشاء {{ type == '1' ? 'استبانة' : 'نموذج' }}
            </button>
            <br><br>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        العنوان
                    </th>
                    <th v-if="type == '1'">
                        عدد المستهدفين
                    </th>
                    <th>
                        من قام بالرد
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="que in ques" :key="que._id">
                        <td>
                            {{ que.title }}
                        </td>
                        <td v-if="type == '1'">
                            {{ que.students_numbers.length }}
                        </td>
                        <td>
                            {{ que.ends }}
                        </td>
                        <td>
                            {{ que.date }}
                        </td>
                        <td>
                            <b-dropdown dropleft style="width: 100%"
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="secondary"
                                size="sm"
                            >
                                <b-dropdown-item v-if="type == '2'" @click="selectedQue = que; share()" v-b-modal.share>
                                    <i class="fa fa-link"></i>
                                    رابط المشاركة
                                </b-dropdown-item>
                                <b-dropdown-item v-if="type == '1'" @click="selectedQue = que;" v-b-modal.send>
                                    <i class="fa fa-paper-plane"></i>
                                    ارسال / اعادة ارسال لمن لم يجيب
                                </b-dropdown-item>
                                <b-dropdown-item v-if="type == '1'" @click="selectedQue = que;" v-b-modal.add>
                                    <i class="fa fa-user-plus"></i>
                                    اضافة طلاب/فصول
                                </b-dropdown-item>
                                <b-dropdown-item @click="selectedQue = que; answersx()" v-b-modal.answers>
                                    <i class="fa fa-list"></i>
                                    عرض الاجابات
                                </b-dropdown-item>
                                <b-dropdown-item @click="selectedQue = que; manage()" v-b-modal.manage>
                                    <i class="fa fa-edit"></i>
                                    تعديل الحقول
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteQue(que._id)">
                                    <i class="fa fa-trash"></i>
                                    حذف القائمة
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <b-modal id="add" title="اضافة مستهدفين/طلاب للاستبانة" hide-footer>
        <div class="alert alert-info g text-center text-dark">
            <span class="text-dark">
                يمكنك استهداف المزيد من الطلاب او الفصول واعادة الارسال لهم ليشاركو في الاستبانة.
                <br>
                وسيتم اضافة الطلاب الغير مضافين مسبقاً، وبعد اضافتهم قم بالضغط على "ارسال / اعادة ارسال لمن لم يجيب"
            </span>
        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 5px;" v-for="c in classes" :key="c.classname+c.classroom">
                <b-form-checkbox
                    v-model="selected_classes"
                    :value="c.classname+','+c.classroom"
                >
                    {{ c.classname + ' - ' + c.classroom }}
                </b-form-checkbox>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="addnow()">
                    اضافة الان
                </button>
            </div>
        </div>
        </b-modal>
        <b-modal id="create" title="انشاء قائمة جديدة" hide-footer>
            <div class="form-group">
              <h5 for="">العنوان</h5>
              <input type="text"
                class="form-control" placeholder="اكتب هنا..." v-model="title">
            </div>
            <div class="form-group">
              <h5 for="">الوصف</h5>
              <textarea class="form-control" placeholder="اختياري" rows="3" v-model="description"></textarea>
            </div>
            <div v-if="type == '1'">
                <h5>الفصول المراد الارسال لها</h5>
                <div class="row">
                    <div class="col-12" style="padding-top: 5px;" v-for="c in classes" :key="c.classname+c.classroom">
                        <b-form-checkbox
                            v-model="selected_classes"
                            :value="c.classname+','+c.classroom"
                        >
                            {{ c.classname + ' - ' + c.classroom }}
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="create()">
                    انشاء 
                    ال{{ type == '1' ? 'استبانة' : 'نموذج' }}
                    <i class="fa fa-arrow-left"></i>
                </button>
            </div>
        </b-modal>
        <b-modal id="share" title="مشاركة الرابط" hide-footer size="sm">
            <input type="text" v-model="link" placeholder="جاري التحميل..." dir="ltr" class="form-control form-control-lg" readonly>
        </b-modal>
        <b-modal id="answers" title="الاجابات" hide-footer size="lg">
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="excel()">
                    <i class="fa fa-file"></i>
                    تصدير الى اكسل
                </button>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered">
                    <thead>
                        <th v-if="type == '1'">
                            الطالب
                        </th>
                        <th>
                            تاريخ الاجابة
                        </th>
                        <th>
                            عرض الاجابة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="answer in answers" :key="answer._id">
                            <td v-if="type == '1'">
                                <div v-if="answer.name">{{ answer.name }}</div>
                            </td>
                            <td>
                                {{ answer.date }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-primary" @click="ans = answer" v-b-modal.ans>
                                    عرض الاجابة
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="send" title="ارسال" hide-footer>
            <div class="form-group">
              <label for="">الرسالة</label>
              <textarea class="form-control" v-model="message" rows="5"></textarea>
            </div>
            <div class="row">
                <div class="col-12 g">
                    ارسال عبر:
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="send('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="send('wa')">
                        <i class="fa fa-whatsapp"></i>
                        اداة المتصفح
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-primary btn-block"  @click="send('sms')">
                        <i class="fa fa-mobile"></i>
                        SMS
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="ans" title="الاجابة" hide-footer size="lg">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الحقل
                        </th>
                        <th>
                            الاجابة
                        </th>
                    </thead>
                    <tbody v-if="selectedQue.arr">
                        <tr v-for="q in JSON.parse(selectedQue.arr)" :key="q.code">
                            <td>
                                {{ q.title }}
                            </td>
                            <td>
                                <div v-if="ans.data">
                                    <div v-if="JSON.parse(ans.data)[q.code]">
                                        <div v-if="q.type == 'checkbox'">
                                            {{ JSON.parse(ans.data)[q.code].join(',') }}
                                        </div>
                                        <div v-if="q.type != 'checkbox'">
                                            {{ JSON.parse(ans.data)[q.code] }}
                                        </div>
                                    </div>
                                    <div v-if="!JSON.parse(ans.data)[q.code]">
                                        <span class="text-muted">لم يتم الاجابة</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="manage" title="التحكم في الحقول" hide-footer size="xl">
            <div class="col-12 g text-center">
                <button class="btn btn-primary" @click="addInput()">
                    <i class="fa fa-plus"></i>
                    اضافة حقل
                </button>
                &nbsp;
                <button class="btn btn-success" @click="save()">
                    <i class="fa fa-save"></i>
                    حفظ التعديلات
                </button>
                <br><br>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                            الحقل
                        </th>
                        <th>
                            نوع الحقل
                        </th>
                        <th>
                            الزامي ام لا	
                        </th>
                        <th>
                            الاختيارات
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="input in arr" :key="input._id">
                            <td>
                                <input type="text" placeholder="اكتب هنا..." class="form-control" v-model="input.title" style="border-radius: 2px;">
                            </td>
                            <td>
                                <select class="form-control" v-model="input.type" style="border-radius: 2px;">
                                    <option value="text">نص</option>
                                    <option value="textarea">مربع نص</option>
                                    <option value="checkbox">اختيارات متعددة</option>
                                    <option value="radio">اختيار واحد</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control" v-model="input.required" style="border-radius: 2px;">
                                    <option :value="true">الحقل الزامي</option>
                                    <option :value="false">الحقل غير الزامي</option>
                                </select>
                            </td>
                            <td>
                                <div v-if="input.type == 'radio' || input.type == 'checkbox'">
                                    <button class="btn btn-primary btn-sm btn-success btn-block text-center" style="border-radius: 2px;" @click="addOption(input)">
                                        <i class="fa fa-plus"></i>
                                        اضافة خيار
                                    </button>
                                    <div class="row" v-for="(option, index) in input.options" :key="index">
                                        <div class="col-10">
                                            <input type="text" placeholder="اكتب هنا..." v-model="input.options[index]" class="form-control form-control-sm" style="border-radius: 2px;">
                                        </div>
                                        <div class="col-2 text-left" style="padding: 0px">
                                            <button class="btn btn-sm text-danger" @click="deleteOption(input, index)">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-outline-danger" style="border-radius: 2px" @click="deleteInput(input)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<script>
const XLSX = require('xlsx');

import { BModal, VBModal, BDropdown, BDropdownItem, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
        BFormCheckbox
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            selectedQue: {},
            user: JSON.parse(localStorage.getItem('user')),
            title: "",
            description: "",
            classes: [],
            selected_classes: [],
            arr: [],
            ques: [],
            ans: {},
            type: window.location.href.split("create2/")[1],
            interval: null,
            link: "",
            links: {},
            answers: [],
            message: `الطالب {name}
برجاء المشاركة في هذا ${window.location.href.split("create2/")[1] == '1' ? 'الاستبيان' : 'النموذج'}
رابط الاجابة: {link}`
        }
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval)
        }
    },
    created(){
        if(!checkPer("mentors")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.interval = setInterval(() => {
            if(window.location.href.split("create2/")[1] != g.type){
                location.reload()
            }
        }, 100);
        $.post(api + '/user/students/list', {
            jwt: g.user.jwt,
            page: 1,
            perpage: 11111
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
        g.getQues()
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push({
                            classname: a.title,
                            classroom: b
                        })
                    })
                })
            }
        }).fail(function(){
        })
    },
    methods: {
        excel(){
        const wb = XLSX.utils.book_new(), g = this;
        var x = [];
        JSON.parse(g.selectedQue.arr).forEach(function(a){
            x.push(a.title)
        })
        const Heading = [
            x
        ];
        var xx = [];
        /*
        <div v-if="ans.data">
            <div v-if="JSON.parse(ans.data)[q.code]">
                <div v-if="q.type == 'checkbox'">
                    {{ JSON.parse(ans.data)[q.code].join(',') }}
                </div>
                <div v-if="q.type != 'checkbox'">
                    {{ JSON.parse(ans.data)[q.code] }}
                </div>
            </div>
            <div v-if="!JSON.parse(ans.data)[q.code]">
                <span class="text-muted">لم يتم الاجابة</span>
            </div>
        </div>
        */
        g.answers.forEach(function(a){
            var xr = [];
            JSON.parse(g.selectedQue.arr).forEach(function(q){
                if(JSON.parse(a.data)[q.code]){
                    if(q.type == "checkbox"){
                        xr.push(JSON.parse(a.data)[q.code].join(','))
                    }else{
                        xr.push(JSON.parse(a.data)[q.code])
                    }
                }else{
                    xr.push("لم يتم الاجابة")
                }
            })
            xx.push(xr)
        })
        
        // creating sheet and adding data from 2nd row of column A.
        // leaving first row to add Heading
        const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
            
        // adding heading to the first row of the created sheet.
        // sheet already have contents from above statement.
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
            
        // appending sheet with a name
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
            
        const fileContent = XLSX.writeFile(wb, g.selectedQue.title + ".xlsx");
        },
        send(method){
            var g = this;
            var messages = [];
            g.students.forEach(function(a){
                if(g.selectedQue.links[a.number] && !g.selectedQue.ends_arr.includes(a.number)){
                    messages.push({
                        phones: a.phones,
                        message: g.message.replace("{name}", a.name).replace("{link}", `({${window.location.href.split("_mentors/")[0]}que2/${g.selectedQue.links[a.number]}})`)
                    })
                }
            })
            $.post(api + '/user/general/short-links', {
                jwt: g.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("mentors")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "mentors"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            g.getQues()
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                alert("حدث خطأ.", 200)
            })
        },
        getQues(){
            var g = this;
            $.post(api + '/user/mentors/ques2', {
                jwt: g.user.jwt,
                type: g.type
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.ques = r.response;
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        deleteQue(id){
            var g = this;
            if(confirm("متأكد من الحذف؟")){
                $.post(api + '/user/mentors/delete-que2', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getQues()
                    }
                }).fail(function(){
                    g.loading = false;
                })
            }
        },
        create(){
            var g = this;
            if(g.selected_classes.length == 0 && g.type == '1'){
                return alert('برجاء اختيار فصل واحد على الاقل')
            }
            $.post(api + '/user/mentors/create2', {
                jwt: g.user.jwt,
                title: g.title,
                type: g.type,
                classes: g.selected_classes,
                description: g.description
            }).then(function(r){
                g.getQues()
                $("#create___BV_modal_header_ > button").click()
            }).fail(function(e){
                console.log(e)
                alert("حدث خطأ..", 200)
            })
        },
        addnow(){
            var g = this;
            if(g.selected_classes.length == 0){
                return alert('برجاء اختيار فصل واحد على الاقل')
            }
            $.post(api + '/user/mentors/create2/add-students', {
                jwt: g.user.jwt,
                id: g.selectedQue._id,
                classes: g.selected_classes,
            }).then(function(r){
                g.getQues()
                if(r.status == 100){
                    alert("تم بنجاح", 100)
                }
                $("#add___BV_modal_header_ > button").click()
            }).fail(function(e){
                console.log(e)
                alert("حدث خطأ..", 200)
            })
        },
        answersx(){
            var g = this;
            g.answers = []
            $.post(api + '/user/mentors/que2-answers', {
                jwt: g.user.jwt,
                id: g.selectedQue._id
            }).then(function(r){
                g.answers = r.response
            }).fail(function(e){
                console.log(e)
                alert("حدث خطأ..", 200)
            })
        },
        manage(){
            var g = this;
            var que = g.selectedQue;
            g.arr = JSON.parse(que.arr);
        },
        addInput(){
            var g = this;
            g.arr.push({
                title: "",
                type: "text",
                options: [],
                required: false,
                code: Math.random()?.toString().split(".")[1]
            })
        },
        addOption(input){
            input.options.push("")
        },
        deleteOption(input, options){
            var o = []
            input.options.forEach(function(a, i){
                if(i != options){
                    o.push(a)
                }
            })
            input.options = o
        },
        deleteInput(input){
            var o = [], g = this;
            this.arr.forEach(function(a){
                if(input.code != a.code){
                    o.push(a)
                }
            })
            this.arr = o;
        },
        save(){
            var g = this;
            this.selectedQue.arr = JSON.stringify(this.arr);
            $.post(api + '/user/mentors/save-que2-inputs', {
                jwt: g.user.jwt,
                id: this.selectedQue._id,
                arr: this.selectedQue.arr
            }).then(function(r){
                g.getQues()
                $("#manage___BV_modal_header_ > button").click()
            }).fail(function(e){
                console.log(e)
                alert("حدث خطأ..", 200)
            })
        },
        share(){
            var g = this;
            g.link = ""
            if(g.links[g.selectedQue._id]){
                g.link = g.links[g.selectedQue._id];
                return false;
            }
            $.post(api + '/user/general/short-links', {
                jwt: g.user.jwt,
                arr: JSON.stringify([
                    {
                        phone: "",
                        message: `({${window.location.href.split("_mentors/")[0]}gque2/${g.selectedQue._id}})`
                    }
                ]),
            }).then(function(r){
                g.link = r.response[0].message
                g.links[g.selectedQue._id] = r.response[0].message;
            })
        }
    }
}
</script>

<style>

</style>